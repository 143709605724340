import { QueryClient, useMutation, useQuery } from 'react-query';
import _ from 'lodash-es';
import axios from 'axios';
import * as qs from 'qs';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchInterval: false,
      suspense: true
    }
  }
});

const baseUrl = `${
  window.location.href.includes('index_dev.php')
    ? window.location.origin + '/index_dev.php'
    : window.location.origin
}`;
const baseUrlApi = baseUrl + '/open_api';

export default async function fetcher(request) {
  try {
    const response = await axios(request || {});
    return response.data;
  } catch (error) {
    throw error;
  }
}

// PRODUCT
// ----------------

export function useSearchQuery(value, limit = 10) {
  return useQuery(
    ['search', value, limit],
    () =>
      fetcher({
        url: `${baseUrlApi}/product/search?title=${value}&limit=${limit}`
      }),
    {
      enabled: !!value
    }
  );
}

export function useProductById(ids, limit = 3) {
  let searchId = '';

  ids.forEach((id) => (searchId += `ids${encodeURIComponent('[]')}=${id}&`));

  return useQuery(
    ['searchProductById', ids, limit],
    () =>
      fetcher({
        url: `${baseUrlApi}/product/search?${searchId}limit=${limit}`
      }),
    {
      cacheTime: 300,
      refetchOnMount: true,
      enabled: ids.length > 0
    }
  );
}

// ADDRESS
export function useAddressQuery() {
  return useQuery('addresses', () =>
    fetcher({
      url: `${baseUrlApi}/address`
    })
  );
}

export function useAddressCreate() {
  return useMutation(
    (data) =>
      fetcher({
        url: `${baseUrlApi}/address`,
        method: 'POST',
        data
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('addresses');
      }
    }
  );
}

export function useAddressUpdate() {
  return useMutation(
    ({ id, data }) =>
      fetcher({
        url: `${baseUrlApi}/address/${id}`,
        method: 'PATCH',
        data
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('addresses');
      }
    }
  );
}

export function useAddressDelete() {
  return useMutation(
    (id) =>
      fetcher({
        url: `${baseUrlApi}/address/${id}`,
        method: 'DELETE'
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('addresses');
      }
    }
  );
}

// DELIVERY MODULES

export function useDeliveryModulessQuery(addressId) {
  const { data } = useDeliveryModes();
  return useQuery(
    ['delivery_modules', addressId],
    () =>
      fetcher({
        url: `${baseUrlApi}/delivery/modules`,
        params: {
          addressId
        }
      }),
    {
      enabled: !!data
    }
  );
}

export function useDeliveryModes() {
  const { data, ...query } = useQuery(['delivery_modes'], () =>
    fetcher({
      url: `${baseUrlApi}/delivery/modules`
    })
  );

  return { ...query, data: [...new Set(data.map((m) => m.deliveryMode))] };
}

// PAYMENT MODULES
export function usePaymentModulessQuery() {
  return useQuery(
    ['payment_modules'],
    () =>
      fetcher({
        url: `${baseUrlApi}/payment/modules`
      }),
    {
      suspense: true
    }
  );
}

// PICKUP LOCATIONS
export function usePickupLocations(params = {}) {
  const res = useQuery(
    ['pickup_locations', params],
    () => {
      return fetcher({
        url: `${baseUrlApi}/delivery/pickup-locations`,
        method: 'GET',
        params
      });
    },
    {
      enabled: params.address && params.city && params.zipCode && true,
      suspense: false
    }
  );

  return res;
}

export function useFindAddress(q = null) {
  return useQuery(
    'find_address',
    () =>
      fetcher({
        url: `https://nominatim.openstreetmap.org/search?format=json`,
        method: 'GET',
        params: {
          bounded: 1,
          addressdetails: 1,
          extratags: 1,
          dedupe: 1,
          q
        }
      }),
    {
      enabled: !!q
    }
  );
}

export function useCheckoutCreate() {
  return useMutation(
    (data) =>
      fetcher({
        url: `${baseUrlApi}/checkout`,
        method: 'POST',
        data
      }),
    {
      onSuccess: (data) => {
        if (data.isComplete) {
          window.location = `${window.location.origin}/order/pay`;
        }
      }
    }
  );
}

// CART
export function useCartQuery() {
  return useQuery(
    'cart',
    () =>
      fetcher({
        url: `${baseUrlApi}/cart`
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('delivery_modules');
        queryClient.invalidateQueries('payment_modules');
      }
    }
  );
}

export async function addToCart({ pseId, quantity, append = true }) {
  try {
    const cartResponse = await fetcher({
      url: `${baseUrlApi}/cart/add`,
      method: 'POST',
      data: {
        pseId,
        quantity,
        append
      }
    });
    return cartResponse;
  } catch (error) {
    throw error;
  }
}

export function useCartItemCreate() {
  return useMutation(
    ({ pseId, quantity, append = true, customizations }) =>
      addToCart({ pseId, quantity, append, customizations }),
    {
      onSuccess: (data) => {
        if (data.cart) {
          queryClient.setQueryData('cart', data.cart);
        }
      }
    }
  );
}

export function useCartItemUpdate(id) {
  return useMutation(
    (quantity) =>
      fetcher({
        url: `${baseUrlApi}/cart/${id}`,
        method: 'PATCH',
        data: {
          quantity
        }
      }),
    {
      onSuccess: (data) => {
        if (data.cart) {
          queryClient.setQueryData('cart', data.cart);
        }
      }
    }
  );
}

export function useCartItemDelete(id) {
  return useMutation(
    () =>
      fetcher({
        url: `${baseUrlApi}/cart/${id}`,
        method: 'DELETE'
      }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData('cart', data);
      }
    }
  );
}

// COUPON
export function useCouponCreate() {
  return useMutation(
    (code) =>
      fetcher({
        url: `${baseUrlApi}/coupon`,
        method: 'POST',
        data: {
          code
        }
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('cart');
      }
    }
  );
}

// CUSTOMER
export function useCustomer() {
  return useQuery(
    'customer',
    () =>
      fetcher({
        url: `${baseUrlApi}/customer`
      }),
    {
      suspense: false
    }
  );
}

// LOGIN
export function useLogin(reload = true) {
  return useMutation(
    ({ email, password, rememberMe = true }) =>
      fetcher({
        url: `${baseUrlApi}/login`,
        method: 'POST',
        data: {
          email,
          password,
          rememberMe
        }
      }),
    {
      onSuccess: () => {
        if (reload) {
          window.location.reload();
        } else {
          queryClient.invalidateQueries('customer');
        }
      }
    }
  );
}

export function useLogout() {
  return useMutation(
    () =>
      fetcher({
        url: `${baseUrlApi}/logout`,
        method: 'POST'
      }),
    {
      onSuccess: () => {
        queryClient.setQueryData('customer', undefined);
      }
    }
  );
}

function getPositionsLg(params) {
  return Promise.all([
    fetcher({
      url: `${baseUrl}/resultats-essai/get_position`,
      method: 'GET',
      params: {
        ...params,
        type: 'intern'
      }
    }),
    fetcher({
      url: `${baseUrl}/resultats-essai/get_position`,
      method: 'GET',
      params: {
        ...params,
        type: 'testimony'
      }
    })
  ]).then(([intern, testimony]) =>
    [...intern, ...testimony].map((el) => ({
      ...el
    }))
  );
}

export function useTestResultPositions(type, params) {
  return useQuery(
    ['test_results', type, params],
    () => {
      return type === 'lg'
        ? getPositionsLg(params)
        : fetcher({
            url: `${baseUrl}/resultats-essai/get_position`,
            method: 'GET',
            params: {
              ...params,
              type: 'independent'
            }
          });
    },
    {
      enabled: !!type,
      suspense: false
    }
  );
}
export function useAllTestResultPositions(params) {
  return useQuery(
    ['all_test_results', params],
    () => {
      return fetcher({
        url: `${baseUrl}/resultats-essai/get_all_position`,
        method: 'GET',
        params: {
          ...params
        }
      });
    },
    {
      suspense: false
    }
  );
}

export function useTestResultData(params = {}) {
  return useQuery(
    ['test_results_data', params],
    () => {
      return fetcher({
        url: `${baseUrl}/resultats-essai/get_data`,
        method: 'GET',
        params: {
          ...params
        }
      });
    },
    {
      enabled: !!params,
      suspense: false
    }
  );
}

export function useTheliaBlock(id, { renderHTML, ...options }) {
  return useQuery(
    ['theliaBlock', id],
    async () => {
      let response = await fetcher({
        url: `${baseUrlApi}/block_group`,
        method: 'GET',
        params: {
          id
        }
      });
      if (!renderHTML) {
        return response;
      }

      const html = await fetcher({
        url: `${baseUrl}?view=ajax/render-thelia-blocks&block_id=${response.id}`
      });
      return { response, html };
    },
    {
      enabled: !!id,
      ...options
    }
  );
}

// OAD
// ----------------------------------------------------------

export function useWeatherStationsList(departement, options) {
  return useQuery(
    ['weather_stations', departement],
    async () => {
      let response = await fetcher({
        url: `${baseUrlApi}/oad/weather_stations`,
        method: 'GET',
        params: {
          departement
        }
      });

      return response;
    },
    options
  );
}

export function useWeatherStation(weatherStationId, options) {
  return useQuery(
    ['weather_station', weatherStationId],
    async () => {
      let response = await fetcher({
        url: `${baseUrlApi}/oad/weather_stations`,
        method: 'GET',
        params: {
          weatherStationId
        }
      });

      return response;
    },
    {
      suspense: false
    }
  );
}

export function useTemperatures(getTemperatures, options) {
  return useQuery(
    [
      'temperatures',
      getTemperatures.cultureTypeId,
      getTemperatures.weatherStationId,
      getTemperatures.altitude,
      getTemperatures.seedingDate
    ],
    async () => {
      let response = await fetcher({
        url: `${baseUrlApi}/oad/temperatures`,
        method: 'GET',
        params: getTemperatures
      });

      return response;
    },
    options
  );
}

export function usePluviometry(getPluviometry, options) {
  return useQuery(
    [
      'pluviometry',
      getPluviometry.cultureTypeId,
      getPluviometry.weatherStationId,
      getPluviometry.altitude,
      getPluviometry.seedingDate
    ],
    async () => {
      let response = await fetcher({
        url: `${baseUrlApi}/oad/rainfalls`,
        method: 'GET',
        params: getPluviometry
      });

      return response;
    },
    options
  );
}

export function useCropCycle(
  {
    weatherStationId,
    altitude,
    varietyId,
    seedingDate,
    desiredHumidity,
    measuredHumidity,
    measuredHumidityDate,
    femaleFloweringDate
  },
  options
) {
  return useQuery(
    [
      'crop_cycle',
      weatherStationId,
      altitude,
      varietyId,
      seedingDate,
      desiredHumidity,
      measuredHumidity,
      measuredHumidityDate,
      femaleFloweringDate
    ],
    async () => {
      let response = await fetcher({
        url: `${baseUrlApi}/oad/crop_cycle`,
        method: 'GET',
        params: {
          weatherStationId,
          altitude,
          varietyId,
          seedingDate,
          desiredHumidity,
          measuredHumidity,
          measuredHumidityDate,
          femaleFloweringDate
        }
      });
      return response;
    },
    options
  );
}

export function useOptimalHarvest(
  {
    weatherStationId,
    altitude,
    varietyId,
    seedingDate,
    desiredHumidity,
    measuredHumidity,
    measuredHumidityDate,
    femaleFloweringDate
  },
  options
) {
  return useQuery(
    [
      'optimal_harvest_date',
      weatherStationId,
      altitude,
      varietyId,
      seedingDate,
      desiredHumidity,
      measuredHumidity,
      measuredHumidityDate,
      femaleFloweringDate
    ],
    async () => {
      let response = await fetcher({
        url: `${baseUrlApi}/oad/optimal_harvest_date`,
        method: 'GET',
        params: {
          weatherStationId,
          altitude,
          varietyId,
          seedingDate,
          desiredHumidity,
          measuredHumidity,
          measuredHumidityDate,
          femaleFloweringDate
        }
      });

      return response;
    },
    options
  );
}

export function useNewOptimalHarvest(options) {
  return useMutation(
    async ({
      weatherStationId,
      altitude,
      varietyId,
      seedingDate,
      desiredHumidity,
      measuredHumidity,
      measuredHumidityDate,
      femaleFloweringDate
    }) => {
      let response = await fetcher({
        url: `${baseUrlApi}/oad/optimal_harvest_date`,
        method: 'GET',
        params: {
          weatherStationId,
          altitude,
          varietyId,
          seedingDate,
          desiredHumidity,
          measuredHumidity,
          measuredHumidityDate,
          femaleFloweringDate
        }
      });

      return response;
    },
    options
  );
}

export function useSeedingCondition(
  { cultureTypeId, groundTypeId, weatherStationId, altitude },
  options
) {
  return useQuery(
    [
      'optimal_harvest_date',
      cultureTypeId,
      groundTypeId,
      weatherStationId,
      altitude
    ],
    async () => {
      let response = await fetcher({
        url: `${baseUrlApi}/oad/seeding_conditions`,
        method: 'GET',
        params: {
          cultureTypeId,
          groundTypeId,
          weatherStationId,
          altitude
        }
      });

      return response;
    },
    options
  );
}

export function useRecommendedPrecocity() {
  return useMutation(async (data) => {
    let response = await fetcher({
      url: `${baseUrlApi}/oad/recommended_precocity`,
      method: 'GET',
      params: data
    });

    return response;
  });
}

export function useGetRecommendedPrecocity(
  { cultureTypeId, weatherStationId, seedingDate, harvestDate },
  options
) {
  return useQuery(
    [
      'recommendedPrecocity',
      weatherStationId,
      cultureTypeId,
      weatherStationId,
      seedingDate,
      harvestDate
    ],
    async () => {
      let response = await fetcher({
        url: `${baseUrlApi}/oad/recommended_precocity`,
        method: 'GET',
        params: {
          cultureTypeId,
          weatherStationId,
          seedingDate,
          harvestDate
        }
      });

      return response;
    },
    { ...options, suspense: true }
  );
}

export function usePrecocities({ cultureTypeId }, options) {
  return useQuery(
    ['precocities', cultureTypeId],
    async () => {
      if (!cultureTypeId) return;

      let response = await fetcher({
        url: `${baseUrlApi}/oad/precocities`,
        method: 'GET',
        params: {
          cultureTypeId
        }
      });

      return response;
    },
    options
  );
}

export function useSuitableVarieties({ precocityId }, options) {
  return useQuery(
    ['suitable_varieties', precocityId],
    async () => {
      if (!precocityId) return;

      let response = await fetcher({
        url: `${baseUrlApi}/oad/suitable_varieties`,
        method: 'GET',
        params: {
          precocityId
        }
      });

      return response;
    },
    options
  );
}

export function useSaveParcel(reload = true) {
  return useMutation(
    async (data) => {
      const parcel = _.cloneDeep(data);

      if (parcel.zipCode) {
        delete parcel.zipCode;
      }

      let response = await fetcher({
        url: `${baseUrlApi}/oad/parcel`,
        method: 'POST',
        data: parcel
      });

      return response;
    },
    {
      onSuccess: () => {
        if (reload) {
          window.location.reload();
        }
      }
    }
  );
}

export function useDeleteParcel() {
  return useMutation(
    async (id) => {
      let response = await fetcher({
        url: `${baseUrlApi}/oad/parcel/${id}`,
        method: 'DELETE'
      });

      return response;
    },
    {
      onSuccess: () => {
        window.location = '/oad';
      }
    }
  );
}

export function useDuplicateParcel() {
  return useMutation(
    async (id) => {
      let response = await fetcher({
        url: `${baseUrlApi}/oad/parcel/duplicate/${id}`,
        method: 'POST'
      });

      return response;
    },
    {
      onSuccess: (data) => {
        // On reload la page, car les actions de la card si on duplique seront tout le temps les actions de la card dupliqué.
        if (window.location.pathname.substring(1) !== 'oad') {
          const parcelTypes = {
            1: 'grape-corn',
            2: 'corn-silage',
            3: 'sunflower',
            4: 'forages',
            5: 'cereals',
            6: 'protein-crops',
            7: 'colz'
          };
          window.location = `/oad#${parcelTypes[data.parcelType]}`;
          return;
        }

        window.location.reload();
      }
    }
  );
}

// OAD PRO
// ============

export function useSaveMultiCriteriaTable() {
  return useMutation(
    async ({ weatherStationId, seedingDate, ...data }) => {
      let response = await fetcher({
        url: `${baseUrlApi}/oad/multicriteria_table`,
        method: 'POST',
        data: {
          ...data,
          seedingDates:
            typeof seedingDate === 'string'
              ? seedingDate.split(',')
              : seedingDate,
          weatherStationArray:
            typeof weatherStationId === 'string'
              ? weatherStationId.split(',')
              : weatherStationId
        }
      });
      return response;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('climateBalance');

        if (!window.location.pathname.includes('multicriteria-table')) {
          window.location = `/oad/multicriteria-table`;
        }

        window.location.reload();
      }
    }
  );
}

export function useGetClimateBalance(
  { weatherStationId, seedingDate, cultureTypeId },
  options
) {
  return useQuery(
    ['climateBalance', weatherStationId, seedingDate, cultureTypeId],
    async () => {
      let response = await fetcher({
        url: `${baseUrlApi}/oad/climate_balance`,
        method: 'GET',
        params: {
          weatherStationId,
          seedingDate,
          cultureTypeId
        }
      });

      return response;
    },
    { ...options, suspense: true }
  );
}

export function useGetCultureStade(
  { weatherStationId, seedingDate, varietyId },
  options
) {
  return useQuery(
    ['cultureStade', weatherStationId, seedingDate, varietyId],
    async () => {
      let response = await fetcher({
        url: `${baseUrlApi}/oad/culture_stade`,
        method: 'GET',
        params: {
          weatherStationId,
          seedingDate,
          varietyId
        }
      });

      return response;
    },
    { ...options, suspense: true }
  );
}

export function useCumulDecade(params, options) {
  return useQuery(
    ['cumule_decade', params.weatherStationId, params.cultureTypeId],
    async () => {
      let response = await fetcher({
        url: `${baseUrlApi}/oad/cumul_decade`,
        method: 'GET',
        params
      });

      return response;
    },
    { ...options, suspense: true }
  );
}

export function useCumulDiffDecade(params, options) {
  return useQuery(
    ['cumul_diff_decade', params.weatherStationId, params.cultureTypeId],
    async () => {
      let response = await fetcher({
        url: `${baseUrlApi}/oad/cumul_diff_decade`,
        method: 'GET',
        params
      });

      return response;
    },
    { ...options, suspense: true }
  );
}

export function useCumulDiffMonth(params, options) {
  return useQuery(
    ['cumul_diff_month', params.weatherStationId, params.cultureTypeId],
    async () => {
      let response = await fetcher({
        url: `${baseUrlApi}/oad/cumul_diff_month`,
        method: 'GET',
        params
      });

      return response;
    },
    { ...options, suspense: true }
  );
}

export function useBestVarieties({ specie, filters, ...params }, options) {
  return useQuery(
    [
      'bestVarieties',
      specie,
      params.location,
      params.precocity,
      params.soil_type,
      params.specie,
      params.type,
      params.weather_station,
      params.sunflowerType,
      ...(filters ? Object.values(filters) : [])
    ],
    async () => {
      const queryString = qs.stringify({ ...params, filters }, { indices: false });
      let response = await fetcher({
        url: `/open_api/expertsystem/${specie}${queryString ? `?${queryString}` : ''}`,
        method: 'GET',
      });

      return response;
    },
    { ...options, suspense: false, enabled: Object.values(params).length > 0 }
  );
}

export function useProductImage(item_id) {
  return useQuery(
    ['product_img', item_id],
    () =>
      fetcher({
        url: `${baseUrlApi}/library/image?itemId=${item_id}&itemType=product`
      }),
    {
      enabled: !!item_id,
      suspense: false,
    }
  );
}
